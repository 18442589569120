import React, { Component } from 'react';
import Topbar from '../components/aboutcomps/topbar';
import MainContent from '../components/aboutcomps/maincontent';


import {Helmet} from "react-helmet";



export default class Pigeons extends Component {
    constructor() {
        super()
        this.state = {
        }
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>About | Homebuyer Connect</title>
                    <link rel="canonical" href="https://homebuyerconnect.com/about" />
                </Helmet>
                <Topbar></Topbar>
                <MainContent></MainContent>
            </React.Fragment>
        )
    }
}
