/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, Suspense, lazy } from "react";
import Loader from "../components/LoadingIndicator";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
// import Image from 'react-bootstrap/Image';
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import axios from "axios";

// import HeroImage from "../images/hero-image.jpg";
import RibbonStar from "../images/ribbon & star.png";
import Approve from "../images/approve-icon.svg";
import Team from "../images/team-icon.svg";
import HouseIcon from "../images/Group 925.svg";
// import {Helmet} from "react-helmet";


import "../css/fha.css";
const LeadGenerator = lazy(() => import("../components/GenerateLead"));

const treasury = {
  opacity: 0,
  position: "absolute",
};
// End of Custom CSS

function trim(theStr) {
  return theStr.replace(/^\s\s*/, "").replace(/\s\s*$/, "");
}

function getNumbers(inVal, isDouble, removeCharsFromPrefix) {
  var retVal = "";
  var okChars = "0123456789";
  var tmpVal = trim(inVal);
  var curChar = "";
  var isDotFound = false;

  if (tmpVal !== "") {
    for (var i = 0; i < tmpVal.length; i++) {
      curChar = tmpVal.charAt(i);

      if (okChars.indexOf(curChar) !== -1) {
        if (!isDotFound && curChar === "." && isDouble) {
          isDotFound = true;
          retVal = retVal + ".";
        }

        if (retVal === "" && removeCharsFromPrefix.indexOf(curChar) !== -1) {
          //Do nothing
        } else {
          retVal += curChar;
        }
      }
    }
  }

  return retVal;
}

export default function Fha() {
  const [applyModalShow, setApplyModalShow] = useState(false);
  const handleApplyModal = () => setApplyModalShow(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const formPage = "fha";
  const [validated, setValidated] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [showTY, setTYModal] = useState(false);
  const [showFail, setFailModal] = useState(false);
  const [honeyPot, setHoneyPot] = useState(false);

  const handleTYClose = () => setTYModal(false);
  const handleFailClose = () => setFailModal(false);

  const handleTelephoneNumber = (evt) => {
    //Save Phone number in local storage for Apply

    var tmpVal = trim(getNumbers(evt.currentTarget.value, false, "01"));
    var FirstThreeDigit = tmpVal.substring(0, 3);
    var FirstSixDigit = tmpVal.substring(0, 6);

    /* Below Pattern will check numbers between below ranges
        456, 555, 900,
        100, 101, ..., 199
        211, 311, ..., 911  ***811 removed
        370, 371, ..., 379
        960, 961, ..., 969
        800, 801, ..., 855  ***removed
        866, 877, 888, 800855
        */
    var patt =
      /^(1[0-9][0-9])|([2-7]11)|(911)|(37[0-9])|(96[0-9])|(456)|(555)|(866)|(877)|(888)|(900)$/;
    var patt2 = /^800855$/;
    if (patt.test(FirstThreeDigit)) {
      evt.currentTarget.value = "";
      return;
    } else if (patt2.test(FirstSixDigit)) {
      evt.currentTarget.value = "";
      return;
    }

    var curChar = "";

    var areaCode = "";
    var firstThree = "";
    var lastFour = "";
    var extension = "";

    if (tmpVal !== "") {
      for (var i = 0; i < tmpVal.length; i++) {
        curChar = tmpVal.charAt(i);

        if (i < 3) areaCode += curChar;
        if (i < 6 && i > 2) firstThree += curChar;
        if (i < 10 && i > 5) lastFour += curChar;
        if (i > 9) extension += curChar;
      }
    }

    var retVal = "";

    /* 6/13/2020 - Dennis
			Do not put the parentesis if we only have the first three digits of the phone number.
			Once we are past that then start formatting the phone number. This was the main issue
			why the phone number couldn't be blanked out in it's entirety.
		*/
    if (areaCode !== "" && firstThree === "") retVal += areaCode;
    else if (areaCode !== "" && firstThree !== "")
      retVal += "(" + areaCode + ")";

    if (firstThree !== "") retVal += " " + firstThree;

    if (lastFour !== "") retVal += "-" + lastFour;

    if (extension !== "") retVal += " x" + extension;
    else setPhoneNumber(retVal);
  };

  function changeClass(ename) {
    ename.target.className = "form-control";
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    let fhaDesktopForm = document.getElementById("fhaDesktopForm");
    let fhaMobileForm = document.getElementById("fhaMobileForm");

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      if (!honeyPot) {
        setFormLoading(true);

        axios
          .post(process.env.REACT_APP_API_EMAIL, {
            firstName,
            lastName,
            phoneNumber,
            email,
            message,
            honeyPot,
            formPage,
          })
          .then((res) => {
            setTYModal(true);
            setFirstName("");
            setLastName("");
            setEmail("");

            setMessage("");
            setPhoneNumber("");
            fhaDesktopForm.classList.remove("was-validated");
            fhaDesktopForm.reset();
            fhaMobileForm.classList.remove("was-validated");
            fhaMobileForm.reset();
            setFormLoading(false);
          })
          .catch(function (error) {
            setFailModal(true);
            fhaDesktopForm.classList.remove("was-validated");
            fhaDesktopForm.reset();
            fhaMobileForm.classList.remove("was-validated");
            fhaMobileForm.reset();
            setFirstName("");
            setLastName("");
            setEmail("");
            setMessage("");
            setPhoneNumber("");

            setFormLoading(false);
          });
      }
    }

    setValidated(true);
  };

    return (
    <>
      <div
        className="hero-fha"
      >
        <Container className="hero-content text-start">
          <Row className="pt-5">
            <Col md={12} lg={6} className="mb-5">
              <h2 className="pt-sm-5 pt-md-0">Do You have an FHA Loan?</h2>
              <p>
                Everyone is jealous of FHA Borrowers right now! Take advantage
                of the new Streamline Refinance program with Homebuyer Connect
                at Sun West.
              </p>

              <ul className="list-unstyled">
                <li>
                  <span className="list-blue-text">FICO as low as 500</span>{" "}
                  (must have made previous 6 mortgage payments on time and not
                  more than 1 late payment in six months prior to that)
                </li>
                <li className="list-blue-text">No Income Verification</li>
                <li className="list-blue-text">No Out of Pocket Fees</li>
                <li className="list-blue-text">No Appraisal</li>
              </ul>

              <p className="orange-text pb-4">
                Don&#39;t wait – start saving money today!
              </p>
              <a
                // href="https://www.swmc.com/ApplyNow/?c=hbc"
                className="clrd-btn text-white text-center ms-0"
                onClick={() => {
                  setApplyModalShow(true);
                }}
              >
                Apply Now
              </a>
            </Col>
            <Col
              sm={12}
              md={10}
              lg={5}
              className="px-0 d-none d-sm-none d-md-none d-lg-block"
            >
              <Form
                className="hero-form shadow need-validation"
                id="fhaDesktopForm"
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
              >
                <h4>Let&#39;s Get Started</h4>
                <p className="orange-text pb-3">Fill out this form below</p>
                <Row style={treasury}>
                  <Form.Group as={Col} sm={5} controlId="quote_name">
                    <strong>
                      <Form.Label>* Get Quote Name: </Form.Label>
                    </strong>
                    <Form.Control
                      type="text"
                      minLength="2"
                      maxLength="12"
                      onChange={(e) => setHoneyPot(true)}
                      data-error="Please fill out this field correctly."
                    />
                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm={5} controlId="quote_code">
                    <strong>
                      <Form.Label>* Get Quote Code: </Form.Label>
                    </strong>
                    <Form.Control
                      type="text"
                      pattern="^[0-9a-zA-Z]{4}$"
                      maxLength="4"
                      onChange={(e) => setHoneyPot(true)}
                      data-error="Please fill out this field correctly."
                    />
                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row>
                  <Col lg={12}>
                    <span className="form-label mb-0">Full Name</span>
                  </Col>
                  <Form.Group as={Col} md="6" controlId="firstName">
                    <Form.Label className="visually-hidden">First name</Form.Label>
                    <Form.Control
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      value={firstName}
                      required
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      First Name is required
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="lastName">
                    <Form.Label className="visually-hidden">Last name</Form.Label>
                    <Form.Control
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      value={lastName}
                      required
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Last Name is required
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} md="6" controlId="userEmail">
                    <Form.Label className="mb-0">Email</Form.Label>
                    <Form.Control
                      type="text"
                      name="email"
                      placeholder="Email Address"
                      value={email}
                      required
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid Email.
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="6" controlId="userPhone">
                    <Form.Label className="mb-0">Phone</Form.Label>
                    <Form.Control
                      style={{ textAlign: "left" }}
                      className="contact-form-field"
                      type="text"
                      required
                      placeholder="Your Phone Number"
                      minLength={14}
                      maxLength={21}
                      pattern="^\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})[ ]*(( |x){1}[ ]?([0-9]){1,7}){0,1}$"
                      onChange={handleTelephoneNumber}
                      onFocus={changeClass.bind(this)}
                      autoComplete="off"
                      value={phoneNumber}
                    />
                    <Form.Control.Feedback type="invalid">
                      Phone Number is required
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group as={Col} md="12" controlId="message">
                    <Form.Label className="mb-0">Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      placeholder="Message"
                      value={message}
                      required
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Message is required
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="pt-0 text-body text-justify d-flex justify-content-center pt-2 pb-1">
                  <Form.Group as={Col} sm={12} className="mb-0 tcp">
                    <p className="consent">
                      <strong>Communication Consent</strong>
                    </p>
                    <p className="mb-1">
                      By submitting your contact information you agree to our{" "}
                      <a
                        href="https://www.swmc.com/terms-and-conditions.php"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms of Use
                      </a>{" "}
                      and our{" "}
                      <a
                        href="https://www.swmc.com/privacy-policy.php"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Security and Privacy Policy
                      </a>
                      . You also expressly consent to having Sun West Mortgage
                      Company contact you about your inquiry by text message or
                      phone (including automatic telephone dialing system or an
                      artificial or prerecorded voice) to the residential or
                      cellular telephone number you have provided, even if that
                      telephone number is on a corporate, state, or national Do
                      Not Call Registry. You do not have to agree to receive
                      such calls or messages as a condition of getting any
                      services from Sun West Mortgage Company or its affiliates.
                      {/* Telephonic communications with Sun West and Celligence are recorded and monitored. You have the option to request the agent to call you back on an unrecorded and unmonitored line. */}
                    </p>
                  </Form.Group>
                </Row>
                <Row className="justify-content-center pt-3">
                  {/* <button className="clrd-btn text-white text-center" type="submit">Send</button> */}
                  <Button
                    className="clrd-btn text-white text-center"
                    type="submit"
                  >
                    {formLoading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="md"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      "Send"
                    )}
                    {formLoading ? " Please wait..." : ""}
                  </Button>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
        <div className="right-triangle"></div>
        <div className="overlay"></div>
      </div>
      <Container className="fha-perks mt-md-n5 mt-lg-5">
        <Row className="pb-md-2 pb-lg-5">
          <Col
            sm={12}
            className="px-0 d-block d-sm-block d-md-block d-lg-none mb-5 mt-n4"
          >
            <Form
              className="hero-form shadow need-validation p-3 bg-white"
              id="fhaMobileForm"
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
            >
              <h4>Let&#39;s Get Started</h4>
              <p className="orange-text pb-3">Fill out this form below</p>
              <Row style={treasury}>
                <Form.Group as={Col} sm={5} controlId="quote_name">
                  <strong>
                    <Form.Label>* Get Quote Name: </Form.Label>
                  </strong>
                  <Form.Control
                    type="text"
                    minLength="2"
                    maxLength="12"
                    onChange={(e) => setHoneyPot(true)}
                    data-error="Please fill out this field correctly."
                  />
                  <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm={5} controlId="quote_code">
                  <strong>
                    <Form.Label>* Get Quote Code: </Form.Label>
                  </strong>
                  <Form.Control
                    type="text"
                    pattern="^[0-9a-zA-Z]{4}$"
                    maxLength="4"
                    onChange={(e) => setHoneyPot(true)}
                    data-error="Please fill out this field correctly."
                  />
                  <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Col lg={12}>
                  <span className="form-label">Full Name</span>
                </Col>
                <Form.Group as={Col} md="6" controlId="firstName">
                  <Form.Label className="visually-hidden">First name</Form.Label>
                  <Form.Control
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    value={firstName}
                    required
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    First Name is required
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="lastName">
                  <Form.Label className="visually-hidden">Last name</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    value={lastName}
                    required
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Last Name is required
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md="6" controlId="userEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    placeholder="Email Address"
                    value={email}
                    required
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid Email.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="6" controlId="userPhone">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    style={{ textAlign: "left" }}
                    className="contact-form-field"
                    type="text"
                    required
                    placeholder="Your Phone Number"
                    minLength={14}
                    maxLength={21}
                    pattern="^\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})[ ]*(( |x){1}[ ]?([0-9]){1,7}){0,1}$"
                    onChange={handleTelephoneNumber}
                    onFocus={changeClass.bind(this)}
                    autoComplete="off"
                    value={phoneNumber}
                  />
                  <Form.Control.Feedback type="invalid">
                    Phone Number is required
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row>
                <Form.Group as={Col} md="12" controlId="message">
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    placeholder="Message"
                    value={message}
                    required
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Message is required
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="pt-0 text-body text-justify d-flex justify-content-center pt-2 pb-1">
                <Form.Group as={Col} sm={12} className="mb-0 tcp">
                  <p className="consent">
                    <strong>Communication Consent</strong>
                  </p>
                  <p className="mb-1">
                  By clicking Get Started below you agree to Sun West Mortgage
                    Company, Inc.'s ("Sun West"){" "}
                    <a
                      href="https://www.swmc.com/terms-and-conditions"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms and Conditions
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://www.swmc.com/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Security Policy
                    </a>{" "}
                    ; and you agree to Celligence International, LLC.'s
                    ("Celligence"){" "}
                    <a
                      href="https://www.celligence.com/terms"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms and Conditions
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://www.celligence.com/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Security and Privacy Policy
                    </a>{" "}
                    , all of which you may access by clicking on the "Terms and
                    Conditions" or "Security Policy" web links contained herein.
                    {/* Telephonic communications with Sun West and Celligence are
                    recorded and monitored. You have the option to request the
                    agent to call you back on an unrecorded and unmonitored
                    line. */}
                  </p>
                </Form.Group>
              </Row>
              <Row className="justify-content-center pt-3">
                <Button
                  className="clrd-btn text-white text-center"
                  type="submit"
                >
                  {formLoading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="md"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Send"
                  )}
                  {formLoading ? " Please wait..." : ""}
                </Button>
              </Row>
            </Form>
          </Col>
          <Col lg={12}>
            <h4 className="page-title text-center">
              The{" "}
              <span
                style={{ position: "relative" }}
                className="light-blue-rectangle px-1"
              >
                Perks
              </span>{" "}
              of Homebuyer Connect
            </h4>
          </Col>
        </Row>
        <Row className="pt-5 justify-content-around">
          <Col md={12} lg={3} className="text-center mb-3 px-2">
            <Card className="shadow py-3 rounded h-100">
              <Card.Img variant="top" src={RibbonStar} className="img-fluid" />
              <Card.Body>
                <Card.Text className="mb-5 px-3 pb-3">
                  40 Years of Experience &amp; Great Rates
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <Link
                  className="button d-block"
                  to="/about"
                  style={{ background: "#DE9C5E 0% 0% no-repeat padding-box" }}
                >
                  Learn More
                </Link>
              </Card.Footer>
            </Card>
          </Col>
          <Col md={12} lg={3} className="text-center mb-3 px-2">
            <Card className="shadow py-3 rounded h-100">
              <Card.Img variant="top" src={Approve} className="img-fluid" />
              <Card.Body>
                <Card.Text className="mb-5 px-3 pb-3">
                  Simple and Fast TRU Approval Process
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <a
                  //   href="https://www.swmc.com/ApplyNow/?c=hbc"
                  className="button text-white text-center"
                  style={{ background: "#00AAE4 0% 0% no-repeat padding-box" }}
                  onClick={() => {
                    setApplyModalShow(true);
                  }}
                >
                  Get TRU Approved
                </a>
              </Card.Footer>
            </Card>
          </Col>
          <Col md={12} lg={3} className="text-center mb-3 px-2">
            <Card className="shadow py-3 rounded h-100">
              <Card.Img variant="top" src={Team} className="img-fluid" />
              <Card.Body>
                <Card.Text className="mb-5 px-3 pb-3">
                  Local Market Manager Here for You Every Step of the Way
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <Link
                  to="/contact"
                  className="button"
                  style={{ background: "#E16D00 0% 0% no-repeat padding-box" }}
                >
                  Find My Market Manager
                </Link>
              </Card.Footer>
            </Card>
          </Col>
          <Col md={12} lg={3} className="text-center mb-3 px-2">
            <Card className="shadow py-3 rounded h-100">
              <Card.Img variant="top" src={HouseIcon} className="img-fluid" />
              <Card.Body>
                <Card.Text className="mb-5 px-3 pb-3">
                  Happy Homeowners Just Like You Across the Country
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <a
                  className="button"
                  href="https://socialsurvey.me/pages/office/sun-west-mortgage/temecula-ca-1"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ background: "#083F88 0% 0% no-repeat padding-box" }}
                >
                  Read Testimonials
                </a>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row className="loan-subfooter" style={{ backgroundSize: "cover" }}>
          <Col>
            <Container>
              <Row className="align-items-center py-4 py-sm-1 py-md-3">
                <Col sm={12} lg={8} className="pt-sm-0 pb-sm-1 py-lg-4">
                  <h2 className="big-white-title">
                    Now is the time to{" "}
                    <span
                      style={{
                        position: "relative",
                        background:
                          "linear-gradient(to top, #E16D00 45%, transparent 45%)",
                      }}
                    >
                      save!
                    </span>
                    <br />
                    What are you waiting for?
                  </h2>
                </Col>
                <Col style={{ textAlign: "end" }}>
                  <a
                    //   href="https://www.swmc.com/ApplyNow/?c=hbc"
                    className="clrd-btn btn text-white my-auto px-5"
                    onClick={() => {
                      setApplyModalShow(true);
                    }}
                  >
                    Apply Now
                  </a>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <Modal
        size="lg"
        centered
        show={showTY}
        onHide={handleTYClose}
        backdrop="static"
      >
        <Modal.Header>
          <h4 className="text-white">Thank You!</h4>
        </Modal.Header>
        <Modal.Body>
          <p>
            Thank You for contacting Homebuyer Connect! We have received your
            request and one of our team members will get back to you as soon as
            possible.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleTYClose}>Close</Button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="lg"
        centered
        show={showFail}
        onHide={handleFailClose}
        backdrop="static"
      >
        <Modal.Body>
          Due to technical difficulties your request could not be processed.
          Please contact <a href="mailto:CSSTeam@swmc.com">CSSTeam@swmc.com</a>.
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleFailClose}>Close</Button>
        </Modal.Footer>
      </Modal>
      <div className="globalApplyModalWrapper">
        <Suspense fallback={<div />}>
          {applyModalShow && (
            <Modal
              size="lg"
              show={applyModalShow}
              onHide={handleApplyModal}
              className="globalApplyModal"
            >
              <Modal.Header closeButton></Modal.Header>
              <Suspense fallback={<Loader />}>
                {applyModalShow && (
                  <LeadGenerator
                    leadSource="NET001"
                    branch="058"
                    message="This is from https://homebuyerconnect.com/."
                    closeModal={handleApplyModal}
                  />
                )}
              </Suspense>
            </Modal>
          )}
        </Suspense>
      </div>
    </>
  );
}
