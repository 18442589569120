/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect, Suspense, lazy } from "react";
import Loader from "../components/LoadingIndicator";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import NumberFormat from "react-number-format";
import { Helmet } from "react-helmet";

// import HeroImage from '../images/listing-hero.jpeg';
import RibbonStar from "../images/ribbon & star.png";
import Approve from "../images/approve-icon.svg";
import Team from "../images/team-icon.svg";
import HouseIcon from "../images/Group 925.svg";
import homescout from "../images/homescout-white.png";
import homeSearch from "../images/start-search-icon.png";


import "../css/listing.css";
const LeadGenerator = lazy(() => import("../components/GenerateLead"));

//  Custom CSS
const treasury = {
  opacity: 0,
  position: "absolute",
};
// End of Custom CSS

function trim(theStr) {
  return theStr.replace(/^\s\s*/, "").replace(/\s\s*$/, "");
}

function getNumbers(inVal, isDouble, removeCharsFromPrefix) {
  var retVal = "";
  var okChars = "0123456789";
  var tmpVal = trim(inVal);
  var curChar = "";
  var isDotFound = false;

  if (tmpVal !== "") {
    for (var i = 0; i < tmpVal.length; i++) {
      curChar = tmpVal.charAt(i);

      if (okChars.indexOf(curChar) !== -1) {
        if (!isDotFound && curChar === "." && isDouble) {
          isDotFound = true;
          retVal = retVal + ".";
        }

        if (retVal === "" && removeCharsFromPrefix.indexOf(curChar) !== -1) {
          //Do nothing
        } else {
          retVal += curChar;
        }
      }
    }
  }

  return retVal;
}

export default function Fha() {
  const [applyModalShow, setApplyModalShow] = useState(false);
  const handleApplyModal = () => setApplyModalShow(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [statesOptions, setStatesOptions] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const formPage = "listing";
  const [validated, setValidated] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [showTY, setTYModal] = useState(false);
  const [showFail, setFailModal] = useState(false);
  const [honeyPot, setHoneyPot] = useState(false);

  const handleTYClose = () => setTYModal(false);
  const handleFailClose = () => setFailModal(false);

  const handleTelephoneNumber = (evt) => {
    //Save Phone number in local storage for Apply

    var tmpVal = trim(getNumbers(evt.currentTarget.value, false, "01"));
    var FirstThreeDigit = tmpVal.substring(0, 3);
    var FirstSixDigit = tmpVal.substring(0, 6);

    /* Below Pattern will check numbers between below ranges
        456, 555, 900,
        100, 101, ..., 199
        211, 311, ..., 911  ***811 removed
        370, 371, ..., 379
        960, 961, ..., 969
        800, 801, ..., 855  ***removed
        866, 877, 888, 800855
        */
    var patt =
      /^(1[0-9][0-9])|([2-7]11)|(911)|(37[0-9])|(96[0-9])|(456)|(555)|(866)|(877)|(888)|(900)$/;
    var patt2 = /^800855$/;
    if (patt.test(FirstThreeDigit)) {
      evt.currentTarget.value = "";
      return;
    } else if (patt2.test(FirstSixDigit)) {
      evt.currentTarget.value = "";
      return;
    }

    var curChar = "";

    var areaCode = "";
    var firstThree = "";
    var lastFour = "";
    var extension = "";

    if (tmpVal !== "") {
      for (var i = 0; i < tmpVal.length; i++) {
        curChar = tmpVal.charAt(i);

        if (i < 3) areaCode += curChar;
        if (i < 6 && i > 2) firstThree += curChar;
        if (i < 10 && i > 5) lastFour += curChar;
        if (i > 9) extension += curChar;
      }
    }

    var retVal = "";

    /* 6/13/2020 - Dennis
			Do not put the parentesis if we only have the first three digits of the phone number.
			Once we are past that then start formatting the phone number. This was the main issue
			why the phone number couldn't be blanked out in it's entirety.
		*/
    if (areaCode !== "" && firstThree === "") retVal += areaCode;
    else if (areaCode !== "" && firstThree !== "")
      retVal += "(" + areaCode + ")";

    if (firstThree !== "") retVal += " " + firstThree;

    if (lastFour !== "") retVal += "-" + lastFour;

    if (extension !== "") retVal += " x" + extension;
    else setPhoneNumber(retVal);
  };

  function changeClass(ename) {
    ename.target.className = "form-control";
  }

  function handleZipCode(zipcode) {
    setZipcode(zipcode);
    if (zipcode.length === 5) {
      axios
        .get(process.env.REACT_APP_SUNSOFT_RESTSERVER_ZIPCODE + zipcode, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          setCity(response.data.cities[0].name);
          setState(response.data.state);
        });
    }
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    let listinDesktopForm = document.getElementById("listinDesktopForm");
    let listinMobileForm = document.getElementById("listinMobileForm");

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      if (!honeyPot) {
        setFormLoading(true);

        axios
          .post(process.env.REACT_APP_API_EMAIL, {
            firstName,
            lastName,
            phoneNumber,
            email,
            message,
            streetAddress,
            zipcode,
            city,
            state,
            honeyPot,
            formPage,
          })
          .then((res) => {
            setTYModal(true);
            setFirstName("");
            setLastName("");
            setEmail("");

            setMessage("");
            setPhoneNumber("");
            setStreetAddress("");
            setCity("");
            setState("");
            setZipcode("");

            listinDesktopForm.classList.remove("was-validated");
            listinDesktopForm.reset();
            listinMobileForm.classList.remove("was-validated");
            listinMobileForm.reset();
            setFormLoading(false);
          })
          .catch(function (error) {
            setFailModal(true);
            listinDesktopForm.classList.remove("was-validated");
            listinDesktopForm.reset();
            listinMobileForm.classList.remove("was-validated");
            listinMobileForm.reset();
            setFirstName("");
            setLastName("");
            setEmail("");
            setMessage("");
            setPhoneNumber("");
            setStreetAddress("");
            setCity("");
            setState("");
            setZipcode("");

            setFormLoading(false);
          });
      }
    }

    setValidated(true);
  };

  useEffect(() => {
    
    axios
      .post(
        process.env.REACT_APP_SUNSOFT_RESTSERVER_STATE_LIST,
        { companyCode: "SUNWST000", domain: "https://homebuyerconnect.com/" },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((response) => {
        // Set the dropdown state list option.
        setStatesOptions(
          response.data.map((item, index) => (
            <option key={index} value={item.code}>
              {item.name}
            </option>
          ))
        );
      });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Listing Report | Homebuyer Connect</title>
        <link
          rel="canonical"
          href="https://homebuyerconnect.com/listinreport"
        />
      </Helmet>
      <Container fluid className="hero-listing px-0">
        <Container className="hero-content text-start">
          <Row>
            <Col md={12} lg={6} className="mb-5">
              <h2 className="pt-md-0">
                You&#39;ll be Shocked How Much Your Home is Worth!
              </h2>
              <p>
                Whether you&#39;re looking to make a move or not, there are
                benefits to knowing how much your home is worth! Fill out the
                form to the right and we&#39;ll send you a free market analysis
                of your home within 24 hours – without ever needing to talk to a
                person. (Though we&#39;re here to help if you want to chat!){" "}
              </p>

              <ul className="list-unstyled">
                <li className="list-blue-text">
                  Free Market Analysis of your home prepared by Jill Brumund
                  with The Hamilton Group, the top team in your market with
                  Berkshire Hathaway HomeServices*.
                </li>
                <li className="list-blue-text">No Commitment</li>
                <li className="list-blue-text">No Phone Calls</li>
                <li className="list-blue-text">No Pressure to Sell</li>
              </ul>

              <p className="orange-text mb-0">
                Don&#39;t wait – find out your home’s value today!
              </p>
              <span className="small-disclaimer">
                *Sun West Mortgage Company, Inc. is acting as the Lender and
                offers financing to qualified borrowers. The Hamilton Group,
                Berkshire Hathaway HomeServices - a third party organization is
                not affiliated with Sun West Mortgage Company, Inc. The name of
                The Hamilton Group, Berkshire Hathaway HomeServices is owned by
                The Hamilton Group, Berkshire Hathaway HomeServices.
              </span>
            </Col>
            <Col
              sm={12}
              md={10}
              lg={5}
              className="px-0 d-none d-sm-none d-md-none d-lg-block"
            >
              <Form
                className="hero-form shadow need-validation"
                id="listinDesktopForm"
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
              >
                <h4>Let&#39;s Get Started</h4>
                <p className="orange-text pb-3">Fill out this form below</p>
                <Row style={treasury}>
                  <Form.Group as={Col} sm={5} controlId="quote_name">
                    <strong>
                      <Form.Label>* Get Quote Name: </Form.Label>
                    </strong>
                    <Form.Control
                      type="text"
                      minLength="2"
                      maxLength="12"
                      onChange={(e) => setHoneyPot(true)}
                      data-error="Please fill out this field correctly."
                    />
                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm={5} controlId="quote_code">
                    <strong>
                      <Form.Label>* Get Quote Code: </Form.Label>
                    </strong>
                    <Form.Control
                      type="text"
                      pattern="^[0-9a-zA-Z]{4}$"
                      maxLength="4"
                      onChange={(e) => setHoneyPot(true)}
                      data-error="Please fill out this field correctly."
                    />
                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row>
                  <Col lg={12}>
                    <span className="form-label mb-0">Full Name*</span>
                  </Col>
                  <Form.Group as={Col} md="6" controlId="firstName">
                    <Form.Label className="visually-hidden">First name</Form.Label>
                    <Form.Control
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      value={firstName}
                      required
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      First Name is required
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="lastName">
                    <Form.Label className="visually-hidden">Last name</Form.Label>
                    <Form.Control
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      value={lastName}
                      required
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Last Name is required
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} md="6" controlId="userEmail">
                    <Form.Label className="mb-0">Email*</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="Email Address"
                      value={email}
                      required
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid Email
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="6" controlId="userPhone">
                    <Form.Label className="mb-0">Phone*</Form.Label>
                    <Form.Control
                      style={{ textAlign: "left" }}
                      className="contact-form-field"
                      type="phone"
                      required
                      placeholder="Your Phone Number"
                      minLength={14}
                      maxLength={21}
                      pattern="^\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})[ ]*(( |x){1}[ ]?([0-9]){1,7}){0,1}$"
                      onChange={handleTelephoneNumber}
                      onFocus={changeClass.bind(this)}
                      autoComplete="off"
                      value={phoneNumber}
                    />
                    <Form.Control.Feedback type="invalid">
                      {phoneNumber.length > 0 && phoneNumber.length < 14
                        ? "Please enter a valid Phone Number"
                        : "Phone Number is required"}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row>
                  <Col lg={12}>
                    <span className="form-label mb-0">Current Address*</span>
                  </Col>
                  <Form.Group as={Col} md="6" controlId="currentAddress">
                    <Form.Label className="visually-hidden">Current Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="streetAddress"
                      placeholder="Street Address"
                      value={streetAddress}
                      required
                      onChange={(e) => {
                        setStreetAddress(e.target.value);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Street Address is required
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="zipcode">
                    <Form.Label className="visually-hidden">Zipcode</Form.Label>
                    <NumberFormat
                      className="form-control"
                      format="#####"
                      name="zipcode"
                      placeholder="Zipcode"
                      value={zipcode}
                      displayType={"input"}
                      required
                      onValueChange={(values) => handleZipCode(values.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Zipcode is required
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group as={Col} md="6" controlId="city">
                    <Form.Label className="visually-hidden">City</Form.Label>
                    <Form.Control
                      type="text"
                      name="city"
                      placeholder="City"
                      value={city}
                      readOnly
                      disabled
                      onChange={(e) => {
                        setCity(e.target.value);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      City is required
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="state">
                    <Form.Label className="visually-hidden">State</Form.Label>
                    <Form.Control
                      value={state}
                      placeholder="Property State"
                      onChange={(e) => {
                        setState(e.target.value);
                      }}
                      as="select"
                      custom
                      readOnly
                      disabled
                    >
                      <option value="">Your State</option>
                      {statesOptions}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      State is required
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group as={Col} md="12" controlId="message">
                    <Form.Label className="mb-0">Any questions?</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      placeholder="Message"
                      value={message}
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Row>
                <Row className="pt-0 text-body text-justify d-flex justify-content-center pt-2 pb-1">
                  <Form.Group as={Col} sm={12} className="mb-0 tcp">
                    <p className="consent">
                      <strong>Communication Consent</strong>
                    </p>
                    <p className="mb-1">
                      By submitting your contact information you agree to our{" "}
                      <a
                        href="https://www.swmc.com/terms-and-conditions.php"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms of Use
                      </a>{" "}
                      and our{" "}
                      <a
                        href="https://www.swmc.com/privacy-policy.php"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Security and Privacy Policy
                      </a>
                      . You also expressly consent to having Sun West Mortgage
                      Company contact you about your inquiry by text message or
                      phone (including automatic telephone dialing system or an
                      artificial or prerecorded voice) to the residential or
                      cellular telephone number you have provided, even if that
                      telephone number is on a corporate, state, or national Do
                      Not Call Registry. You do not have to agree to receive
                      such calls or messages as a condition of getting any
                      services from Sun West Mortgage Company or its affiliates.
                      {/* Telephonic communications with Sun West and Celligence are recorded and monitored. You have the option to request the agent to call you back on an unrecorded and unmonitored line. */}
                    </p>
                  </Form.Group>
                </Row>
                <Row className="justify-content-center pt-3">
                  {/* <button className="clrd-btn text-white text-center" type="submit">Send</button> */}
                  <Button
                    className="clrd-btn text-white text-center"
                    type="submit"
                  >
                    {formLoading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="md"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      "Send"
                    )}
                    {formLoading ? " Please wait..." : ""}
                  </Button>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
        <div className="right-triangle"></div>
        <div className="overlay"></div>
      </Container>
      <Container className="fha-perks">
        <Row className="pb-md-2 pb-lg-5">
          <Col
            sm={12}
            className="px-0 d-block d-sm-block d-md-block d-lg-none mb-5 mt-n4"
          >
            <Form
              className="hero-form shadow need-validation p-3 bg-white"
              id="listinMobileForm"
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
            >
              <h4>Let&#39;s Get Started</h4>
              <p className="orange-text pb-3">Fill out this form below</p>
              <Row style={treasury}>
                <Form.Group as={Col} sm={5} controlId="quote_name">
                  <strong>
                    <Form.Label>* Get Quote Name: </Form.Label>
                  </strong>
                  <Form.Control
                    type="text"
                    minLength="2"
                    maxLength="12"
                    onChange={(e) => setHoneyPot(true)}
                    data-error="Please fill out this field correctly."
                  />
                  <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm={5} controlId="quote_code">
                  <strong>
                    <Form.Label>* Get Quote Code: </Form.Label>
                  </strong>
                  <Form.Control
                    type="text"
                    pattern="^[0-9a-zA-Z]{4}$"
                    maxLength="4"
                    onChange={(e) => setHoneyPot(true)}
                    data-error="Please fill out this field correctly."
                  />
                  <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Col lg={12}>
                  <span className="form-label">Full Name*</span>
                </Col>
                <Form.Group as={Col} md="6" controlId="firstName">
                  <Form.Label className="visually-hidden">First name</Form.Label>
                  <Form.Control
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    value={firstName}
                    required
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    First Name is required
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="lastName">
                  <Form.Label className="visually-hidden">Last name</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    value={lastName}
                    required
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Last Name is required
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md="6" controlId="userEmail">
                  <Form.Label>Email*</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    value={email}
                    required
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid Email.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="6" controlId="userPhone">
                  <Form.Label>Phone*</Form.Label>
                  <Form.Control
                    style={{ textAlign: "left" }}
                    className="contact-form-field"
                    type="phone"
                    required
                    placeholder="Your Phone Number"
                    minLength={14}
                    maxLength={21}
                    pattern="^\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})[ ]*(( |x){1}[ ]?([0-9]){1,7}){0,1}$"
                    onChange={handleTelephoneNumber}
                    onFocus={changeClass.bind(this)}
                    autoComplete="off"
                    value={phoneNumber}
                  />
                  <Form.Control.Feedback type="invalid">
                    {phoneNumber.length > 0 && phoneNumber.length < 14
                      ? "Please enter a valid phone number"
                      : "Phone Number is required"}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Col lg={12}>
                  <span className="form-label mb-0">Current Address*</span>
                </Col>
                <Form.Group as={Col} md="6" controlId="currentAddress">
                  <Form.Label className="visually-hidden">Current Address</Form.Label>
                  <Form.Control
                    type="text"
                    name="streetAddress"
                    placeholder="Street Address"
                    value={streetAddress}
                    required
                    onChange={(e) => {
                      setStreetAddress(e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Street Address is required
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="zipcode">
                  <Form.Label className="visually-hidden">Zipcode</Form.Label>
                  <NumberFormat
                    className="form-control"
                    format="#####"
                    name="zipcode"
                    placeholder="Zipcode"
                    value={zipcode}
                    displayType={"input"}
                    required
                    onValueChange={(values) => handleZipCode(values.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Zipcode is required
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row>
                <Form.Group as={Col} md="6" controlId="city">
                  <Form.Label className="visually-hidden">City</Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    placeholder="City"
                    value={city}
                    readOnly
                    disabled
                    onChange={(e) => {
                      setCity(e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    City is required
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="state">
                  <Form.Label className="visually-hidden">State</Form.Label>
                  <Form.Control
                    value={state}
                    placeholder="Property State"
                    onChange={(e) => {
                      setState(e.target.value);
                    }}
                    as="select"
                    custom
                    readOnly
                    disabled
                  >
                    <option value="">Your State</option>
                    {statesOptions}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    State is required
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row>
                <Form.Group as={Col} md="12" controlId="message">
                  <Form.Label>Any questions?</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    placeholder="Message"
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                  />
                </Form.Group>
              </Row>
              <Row className="pt-0 text-body text-justify d-flex justify-content-center pt-2 pb-1">
                <Form.Group as={Col} sm={12} className="mb-0 tcp">
                  <p className="consent">
                    <strong>Communication Consent</strong>
                  </p>
                  <p className="mb-1">
                    By submitting your contact information you agree to our{" "}
                    <a
                      href="https://www.swmc.com/terms-and-conditions.php"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms of Use
                    </a>{" "}
                    and our{" "}
                    <a
                      href="https://www.swmc.com/privacy-policy.php"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Security and Privacy Policy
                    </a>
                    . You also expressly consent to having Sun West Mortgage
                    Company contact you about your inquiry by text message or
                    phone (including automatic telephone dialing system or an
                    artificial or prerecorded voice) to the residential or
                    cellular telephone number you have provided, even if that
                    telephone number is on a corporate, state, or national Do
                    Not Call Registry. You do not have to agree to receive such
                    calls or messages as a condition of getting any services
                    {/* from Sun West Mortgage Company or its affiliates. Telephonic communications with Sun West and Celligence are recorded and monitored. You have the option to request the agent to call you back on an unrecorded and unmonitored line. */}
                  </p>
                </Form.Group>
              </Row>
              <Row className="justify-content-center pt-3">
                <Button
                  className="clrd-btn text-white text-center"
                  type="submit"
                >
                  {formLoading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="md"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Send"
                  )}
                  {formLoading ? " Please wait..." : ""}
                </Button>
              </Row>
            </Form>
          </Col>
          <Col lg={12} className="text-center my-5 pt-md-5">
            <h4 className="page-title mb-3">Ready to Make a Move?</h4>
            <p>
              Utilize our private home search app HomeScout to find your dream
              home today!
            </p>

            {/* <Image fluid src={homescout} /> */}
            <div
              className="rounded shadow p-3">
              <Image fluid className="boxIcon" src={homeSearch} />
              <h4 className="pb-1 pb-sm-2 pb-md-2">Start Your Home Search</h4>
              <Image fluid src={homescout} />
              <p>
                <a
                  href="https://homescout.realty.com/l/john-brumund"
                  className="btn text-white clrd-btn ms-0 mt-3"
                  size="md"
                  target="_blank"
                >
                  Search Now
                </a>
              </p>
            </div>
          </Col>
        </Row>
        <Row className="pt-5 justify-content-around">
          <Col lg={12} className="mb-3">
            <h4 className="page-title text-center">
              The{" "}
              <span
                style={{ position: "relative" }}
                className="light-blue-rectangle px-1"
              >
                Perks
              </span>{" "}
              of Homebuyer Connect
            </h4>
          </Col>
          <Col md={12} lg={3} className="text-center mb-3 px-2">
            <Card className="shadow py-3 rounded h-100">
              <Card.Img variant="top" src={RibbonStar} className="img-fluid" />
              <Card.Body>
                <Card.Text className="mb-5 px-3 pb-3">
                  40 Years of Experience &amp; Great Rates
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <Link
                  className="button d-block"
                  to="/about"
                  style={{ background: "#DE9C5E 0% 0% no-repeat padding-box" }}
                >
                  Learn More
                </Link>
              </Card.Footer>
            </Card>
          </Col>
          <Col md={12} lg={3} className="text-center mb-3 px-2">
            <Card className="shadow py-3 rounded h-100">
              <Card.Img variant="top" src={Approve} className="img-fluid" />
              <Card.Body>
                <Card.Text className="mb-5 px-3 pb-3">
                  Simple and Fast TRU Approval Process
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <a
                  className="button text-white text-center"
                  style={{
                    background: "#00AAE4 0% 0% no-repeat padding-box",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setApplyModalShow(true);
                  }}
                >
                  Get TRU Approved
                </a>
              </Card.Footer>
            </Card>
          </Col>
          <Col md={12} lg={3} className="text-center mb-3 px-2">
            <Card className="shadow py-3 rounded h-100">
              <Card.Img variant="top" src={Team} className="img-fluid" />
              <Card.Body>
                <Card.Text className="mb-5 px-3 pb-3">
                  Local Market Manager Here for You Every Step of the Way
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <Link
                  to="/contact"
                  className="button"
                  style={{ background: "#E16D00 0% 0% no-repeat padding-box" }}
                >
                  Find My Market Manager
                </Link>
              </Card.Footer>
            </Card>
          </Col>
          <Col md={12} lg={3} className="text-center mb-3 px-2">
            <Card className="shadow py-3 rounded h-100">
              <Card.Img variant="top" src={HouseIcon} className="img-fluid" />
              <Card.Body>
                <Card.Text className="mb-5 px-3 pb-3">
                  Happy Homeowners Just Like You Across the Country
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <a
                  className="button"
                  href="https://socialsurvey.me/pages/office/sun-west-mortgage/temecula-ca-1"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ background: "#083F88 0% 0% no-repeat padding-box" }}
                >
                  Read Testimonials
                </a>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row className="loan-subfooter" style={{ backgroundSize: "cover" }}>
          <Col>
            <Container>
              <Row className="align-items-center py-4 py-sm-1 py-md-3">
                <Col sm={12} lg={8} className="pt-sm-0 pb-sm-1 py-lg-4">
                  <h2 className="big-white-title">
                    Now is the time to{" "}
                    <span
                      style={{
                        position: "relative",
                        background:
                          "linear-gradient(to top, #E16D00 45%, transparent 45%)",
                      }}
                    >
                      buy!
                    </span>
                    <br />
                    What are you waiting for?
                  </h2>
                </Col>
                <Col style={{ textAlign: "end" }}>
                  <a
                    className="clrd-btn btn text-white my-auto px-5"
                    onClick={() => {
                      setApplyModalShow(true);
                    }}
                  >
                    Apply Now
                  </a>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <Modal
        size="lg"
        centered
        show={showTY}
        onHide={handleTYClose}
        backdrop="static"
      >
        <Modal.Header>
          <h4 className="text-white">Thank You!</h4>
        </Modal.Header>
        <Modal.Body>
          <p>
            Thank You for contacting Homebuyer Connect! We have received your
            request and one of our team members will get back to you as soon as
            possible.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleTYClose}>Close</Button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="lg"
        centered
        show={showFail}
        onHide={handleFailClose}
        backdrop="static"
      >
        <Modal.Body>
          Due to technical difficulties your request could not be processed.
          Please contact <a href="mailto:CSSTeam@swmc.com">CSSTeam@swmc.com</a>.
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleFailClose}>Close</Button>
        </Modal.Footer>
      </Modal>
      <div className="globalApplyModalWrapper">
        <Suspense fallback={<div />}>
          {applyModalShow && (
            <Modal
              size="lg"
              show={applyModalShow}
              onHide={handleApplyModal}
              className="globalApplyModal"
            >
              <Modal.Header closeButton></Modal.Header>
              <Suspense fallback={<Loader />}>
                {applyModalShow && (
                  <LeadGenerator
                    leadSource="NET001"
                    branch="058"
                    message="This is from https://homebuyerconnect.com/."
                    closeModal={handleApplyModal}
                  />
                )}
              </Suspense>
            </Modal>
          )}
        </Suspense>
      </div>
    </>
  );
}
